import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-dropdown"
export default class extends Controller {
  static targets = [ "checkbox", "placeholder" ]
  connect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.updateLabel.bind(this))
    })
    this.updateLabel()
  }

  updateLabel() {
    const checkedCheckboxes = this.checkboxTargets.filter(checkbox => checkbox.checked)
    const checkedLabels = checkedCheckboxes.map(checkbox => checkbox.dataset.label)
    if(checkedLabels.length === 0) {
      this.placeholderTarget.textContent = this.placeholderTarget.dataset.default
      this.placeholderTarget.classList.remove('text-gray-800')
      this.placeholderTarget.classList.add('text-gray-500')
      return
    }
    if(checkedCheckboxes.length === this.checkboxTargets.length) {
      this.placeholderTarget.textContent = this.placeholderTarget.dataset.all
      this.placeholderTarget.classList.add('text-gray-800')
      this.placeholderTarget.classList.remove('text-gray-500')
      return
    }
    this.placeholderTarget.textContent = checkedLabels.join(', ')
    this.placeholderTarget.classList.add('text-gray-800')
    this.placeholderTarget.classList.remove('text-gray-500')

  }

  disconnect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.removeEventListener('change', this.updateLabel.bind(this))
    })
  }
}
