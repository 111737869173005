import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-fill"
export default class extends Controller {
  static targets = ["amount", "caHtFull"];

  connect() {
    // Trigger calculation when the amount input field changes
    this.amountTarget.addEventListener("input", this.calculateCaHtFull.bind(this));
  }

  calculateCaHtFull() {
    const amount = parseFloat(this.amountTarget.value);
    let caHtFullValue;

    if (!isNaN(amount)) {
      // Calculate ca_ht_full as 9% of amount with a minimum of 8250
      caHtFullValue = Math.max(amount * 0.09, 8250);
    } else {
      caHtFullValue = ""; // Clear the value if the amount is not a valid number
    }

    // Update the value of the ca_ht_full input field
    this.caHtFullTarget.value = caHtFullValue;
  }
}
