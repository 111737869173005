import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  static targets = ["inputState", "inputCategory", "inputDismissedAt"]

  connect() {
    this.inputStateTarget.addEventListener("change", this.onStateChange)
    this.showHideDismissedAt()
  }

  onStateChange = (event) => {
    this.showHideDismissedAt()
  }

  showHideDismissedAt = () => {
    if(['financial', 'health'].includes(this.inputCategoryTarget.value)) {
      if(this.inputStateTarget.value === 'dismissed') {
        this.inputDismissedAtTarget.parentNode.classList.remove('hidden')
        this.inputDismissedAtTarget.value = new Date().toISOString().slice(0, 10)
      }
      else {
        this.inputDismissedAtTarget.parentNode.classList.add('hidden')
        this.inputDismissedAtTarget.value = ''
      }
    }
  }
}
