import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    if ( this.data.get("autoclose") == "1" ) {
      setTimeout(
        () => this.element.remove(), 3000
      )
    }
  }

  close() {
    this.element.remove()
  }
}
