import { Application } from "@hotwired/stimulus"
import Dropdown from 'stimulus-dropdown'

const application = Application.start()
application.register('dropdown', Dropdown)

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

window.setFormatter = function(chart, formatter) {
  if(!chart) return
  let options = chart.getOptions()
  options.library.plugins.datalabels.formatter = formatter
  chart.setOptions(options)
}

window.hideZeroOnChart = function(chart) {
  if(!chart) return
  let options = chart.getOptions()
  options.library.plugins.datalabels.display = function(context) {
    return context.dataset.data[context.dataIndex] != 0
  }
  chart.setOptions(options)
}

window.setOnClickToChart = function (chart, callback) {
  if(!chart) return
  let options = chart.getOptions()
  options.library.onClick = callback;
  chart.setOptions(options)
}

window.currency_formatter = function(value, context) {
  return value.toFixed(2).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ') + ' €';
}

window.percent_formatter = function(value, context) {
  return value+'%';
}

export { application }
