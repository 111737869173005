// Entry point for the build script in your package.json
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "./channels"

import Chartkick from "chartkick"
import Chart from "chart.js/auto"
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

Chartkick.use(Chart)

Rails.start()
ActiveStorage.start()

import "./controllers"
