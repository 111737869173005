import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  connect() {
    this.tabs = this.element.querySelectorAll("[data-tabs-target]")
    this.panels = this.element.querySelectorAll("[data-tabs-panel]")
    this.showTab(this.tabs[0])
  }

  showTab(tab) {
    if (tab instanceof PointerEvent) {
      tab = tab.target
    }
    this.tabs.forEach(t => {
      t.classList.toggle("text-gray-400", t != tab)
      t.classList.toggle("text-primary-700", t == tab)
      t.classList.toggle("border-transparent", t != tab)
      t.classList.toggle("border-primary-700", t == tab)
    })
    this.panels.forEach(p => {
      p.classList.toggle("hidden", p.dataset.tabsPanel != tab.dataset.tabsTarget)
    })
  }
}
